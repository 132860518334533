.newsCards{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column-reverse;
    width: 100%; 
}




@media(min-width: 500px){
    .newsCards{
        width: 65%; 
        flex-direction: row-reverse; 
        align-items: stretch; 
        justify-content: space-around;
    }
}


@media(min-width: 700px){
    .newsCards{
        width: 65%; 
        flex-direction: column-reverse;
        align-items:center; 
    }
}

@media(min-width:900px){
    .newsCards{
        flex-direction: row;         
    }
}

@media(min-width:1000px){
    .newsCards{
        align-items: initial;
        margin-top: 10px;     
    }
}

@media(min-width:1100px){
    .newsCards{
        width: 72%;     
    }
}

.loading-political{
    font-size: 22px; 
    width: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    height: 80vh; 
    align-items: center; 



    color: white;
    font-weight: normal;
    text-shadow: 1px 1px 2px rgb(82, 0, 99), 0 0 25px #f14247, 0 0 5px #c42fff;
    font-family: zeit; 
  
}

.desktop-landing-container{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    background: white; 
}

.desktop-sidebar{
    display: none; 
}

@media(min-width: 500px){
    .desktop-sidebar{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column; 
        color: white; 
        width: 35%; 
        background: black;
        margin-bottom: 0px;    
    }
}


@media(min-width: 700px){
    .desktop-sidebar{
       width: 35%;
    }
}

@media(min-width: 1100px){
    .desktop-sidebar{
       width: 28%;
    }
}

@media(min-width: 500px){
    .desktop-sidebar h2{
        font-weight: 500; 
        text-align: center; 
        letter-spacing: 1.1px; 
        font-family: zeit;
        font-size: 25px;
        color: rgb(250, 244, 244); 
        text-shadow:
            -1px -1px 0 purple,
            1px -1px 0 purple,
            -1px 1px 0 purple,
            1px 1px 0 purple;
    }
}

@media(min-width: 700px){
    .desktop-sidebar h2{
        font-size: 25px;
        
    }
}


