.my-web-navbar{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between;
    align-items: flex-end; 
    padding: 5px 5px; 
    font-size: 18px; 
    background: #ffffffb8;
    border: 1px solid white; 
    box-shadow: 4px 2px 3px 1px rgb(56, 0, 56);
}

.web-card-container{
    color: black; 
    font-weight: bold; 
    background: white; 
    font-family: 'Alegreya Sans', 'serif';
    border-top: 1px solid black;
    border-bottom: 1px solid black; 
    width: 100%;
    margin: 10px 0px 5px 0px;
}

@media(min-width: 320px){
    .web-card-container{
        width: 100%;
        box-shadow: 1px 2px 4px 2px;
        margin: 10px 0px 5px 0px; 
    }
}

@media(min-width: 400px){
    .web-card-container{
        width: 75%;
        box-shadow: 1px 2px 4px 2px;
        margin: 10px 0px 5px 0px; 
    }
}

@media(min-width: 450px){
    .web-card-container{
        width: 65%;
        box-shadow: 1px 2px 4px 2px;
        margin: 10px 0px 5px 0px; 
    }
}

@media(min-width: 500px){
    .web-card-container{
        width: 90%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 550px){
    .web-card-container{
        width: 80%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 625px){
    .web-card-container{
        width: 70%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 700px){
    .web-card-container{
        width: 60%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 750px){
    .web-card-container{
        width: 40%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 1000px){
    .web-card-container{
        width: 30%;
        box-shadow: 1px 2px 4px 2px;
    }
}

.user-info{
    display: flex; 
    flex-wrap: wrap; 
    align-items: center; 
    justify-content: space-between; 
    padding: 0px 15px;
    margin-top: 15px; 
}

.user-info a{
    font-size: 16px; 
}

@media(min-width:320px){
    .user-info a{
        font-size: 16px;
    }
}

.card-title{
    padding: 0px 15px;
    margin-bottom: 0px;
    font-size: 21px;
}

@media(min-width: 360px){
    .card-title{
        font-size: 16px;
    }
}

.web-article-content{
    padding-left: 13px; 
    font-size: 17px;
}

.web-article-content p{
    font-size: 16px; 
    margin-bottom: 0px; 
}

@media(min-width:360px){
    .web-article-content p{
        font-size: 14px;
    }
}

.web-links{
    display: flex; 
    justify-content: center; 
    font-size: 13px; 
}

.user-selectors{
    display: flex; 
    width: 90%;
    flex-wrap: wrap; 
    justify-content: space-between; 
    margin: auto; 
    align-items: flex-end;
    font-size: 13px; 
    padding: 10px; 
}

.userSelectors a p{
    margin-bottom: 0px; 
}

.username-thumbnail{
    padding-left: 13px; 
    padding-top: 13px; 
}

.myWeb-card-container{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column-reverse;
    align-items:center; 
}

.myWeb-box{
    height: 20px; 
    width: 100%; 
    background: black; 
    margin: 0; 
    color: white; 
    text-align: center; 
    font-family: 'Alegreya Sans', 'serif';
}

.web-card-username{
    margin: 0px 
}

.loading-my-web{
    font-size: 22px; 
    width: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    height: 80vh; 
    align-items: center; 
}

.my-web-navbar img{
    border: 2px solid rgb(39, 0, 51);
    border-radius: 50%; 
    padding: 3px;  
}

.desktop-container{
    display: flex; 
    flex-wrap: wrap; 
}

.navbar-myWebCards-container{
    width: 100%;
}

@media(min-width: 500px){
    .navbar-myWebCards-container{
        width: 75%;
    }
}

.myWeb-sidebar{
    display: none; 
}

@media(min-width: 500px){
    .myWeb-sidebar{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column; 
        font-weight:500; 
        color: white; 
        width: 25%; 
        background: black;
        min-height: 100vh;
        margin-bottom: 0px;    
        font-family: 'Alegreya Sans', 'serif';
        text-shadow:
            -1px -1px 0 purple,
            1px -1px 0 purple,
            -1px 1px 0 purple,
            1px 1px 0 purple;
    }
}

@media(min-width:500px){
    .myWeb-sidebar h2{
        text-align: center;
        font-size: 25px; 
        font-weight: 500; 
        margin-top: 10px; 
        text-decoration: underline;
    }
}

@media(min-width:500px){
    .myWeb-sidebar h3{
        font-weight: 500;
    }
}

@media(min-width:500px){
    h3.myWeb-header {
        text-align: center;
        text-decoration: none;
        margin-top: 10px; 
        font-size: 25px; 
    }
}

.link-preview{
    display: flex; 
    flex-wrap: wrap; 
    justify-content:space-around;
    align-items:center; 
    padding: 0px 15px;
}

.preview-image {
    width: 35%; 
    height: 100px; 
}

@media(min-width: 360px){
    .preview-image{
        width: 40%;
    }
}

.link-preview h3{
    width: 60%;
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    font-size: 16px;
    text-decoration: none; 
}

@media(min-width: 360px){
    .link-preview h3{
        font-size: 14px;
        width: 55%; 
    }
}

.currents-banner-myWeb{
    color: white;
    font-weight: normal;
    font-family: "'Alegreya Sans', 'serif'";
    font-size: 35px; 
    padding-top: 15px; 
    padding-bottom: 15px; 
    text-shadow: 1px 1px 2px rgb(82, 0, 99), 0 0 25px #f14247, 0 0 5px #c42fff;
    text-align: center;
    margin: 0px; 
    font-family: zeit; 
    width: 100%; 
}

.currents-banner-myWeb-top{
    display: none; 
}

@media(min-width: 961px){ 
    .currents-banner-myWeb-top{
        display: block; 
        width: 100%; 
        font-weight: 700;
        text-align: center; 
        text-decoration: underline; 
        font-family: 'Alegreya Sans', 'serif';
        font-size: 35px;
        color: black;
    }
}

.user-selectors a{
    cursor: pointer;
}

.user-selectors p{
    font-size: 17px;
}

@media(min-width:360px){
    .user-selectors p{
        font-size: 13px;
    }
}

.web-card-container hr{
    border-bottom: 0px solid black;
}

.content-padding{
    padding: 0px 2px; 
}