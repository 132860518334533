.credits-header{
    display: flex; 
    justify-content: center;  
}

.credits{
    text-align: center; 
    line-height: .5;
    color: white; 
}

.credit{
    font-weight: 700;
    text-decoration: underline; 
    font-size: 19px; 
    font-family: 'Alegreya Sans', 'serif';
}

.disclaimer{
    padding: 10px;
    line-height: 1.3;
    font-weight: bold;
}

.disclaimer p{    
    margin-top: 0px;
    max-width: 280px;
    text-align: center;
    margin: 0px auto;
    color: white; 
}

.credits-banner{
    color: white; 
    font-weight: normal;
    font-size: 28px;
    margin:  8px auto; 
    font-family: 'Playfair Display', 'serif';
    border-bottom: 1px solid black;
}