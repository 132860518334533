.underground-form-container{
    width: 100%; 
    text-align: center;
}

.underground-form-container form{
    width: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
}

@media(min-width: 560px){
    .underground-form-container form{
        flex-direction: column;
        align-items:center;
    }
}

.underground-form-container p{
    max-width: 269px;
    width: 100%; 
    border: 1px solid black; 
    padding: 7px; 
    margin-top: 0px;
}



.input-container input{
    max-width: 280px;
    width: 87%; 
    height: 35px; 
    margin-bottom: 20px; 
    text-align: center; 
}

.input-container input:last-of-type{
    margin-bottom: 0px; 
}

.postNews-container input::placeholder{
    text-align: center; 
    font-size: 22px; 
    border-radius: 15px; 
    padding-right: 5px; 
}

.postNews-container textarea::placeholder{
    text-align: center;  
    padding-right: 5px; 
    padding-top: 30px; 
    font-size: 22px; 
}

.post-underground-textarea{
    max-width: 280px;
    width: 86%; 
    height: 120px; 
    margin-bottom: 20px; 
}

.counter{
    max-width: 284px;
    width: 88%;
    display: flex;
    justify-content: flex-end;
    transform: translateY(-20px);
    font-size: 14px;
    font-weight: 700;
    font-family: 'Alegreya Sans', 'serif';
}

@media(min-width: 560px){
    .counter{
        transform: translateY(-40px)
    }
}

  .post-underground-header{
    font-family: 'Alegreya Sans', 'serif';
    display: inline-block;
    color: white; 
}

.select-container{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-evenly; 
    width: 100%; 
    align-items: center; 
    font-family: 'Alegreya Sans', 'serif';
    max-width: 300px; 
}

.select-container select{
    height: 40px; 
    min-width: 120px; 
}

.input-container button{
    max-width: 280px;
    width: 88%;
    padding: 8px; 
    margin-bottom: 20px; 
}
