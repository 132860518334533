.menu{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column; 
    align-items: center; 
    background: black; 
    padding-bottom: 15px;     
    line-height: 0.7; 
    font-family: 'Alegreya Sans', 'serif';
    font-size: 18px;
    color: rgb(250, 244, 244); 
    font-weight: 500; 
    letter-spacing: 1.2px; 
    text-shadow:
        -1px -1px 0 purple,
        1px -1px 0 purple,
        -1px 1px 0 purple,
        1px 1px 0 purple;
}

.hide{
    display: none; 
}

.menu h3{
    letter-spacing: 1.7px;
    text-decoration: none; 
    font-weight: 700;
    color: rgb(250, 244, 244); 
}

.menu h3:hover{ 
    color: purple; 
    font-size: 28px;
    transition: 0.2s; 
    cursor: pointer;
    text-shadow:
    -1px -1px 0 maroon,
    1px -1px 0 maroon,
    -1px 1px 0 maroon,
    1px 1px 0 maroon;
}

.hidden{
    display: none; 
}

.menu h1{
    font-size: 25px;
    text-decoration: underline; 
}