.author-published-container{
    display: flex; 
    flex-wrap: wrap; 
    width: 100%;
    justify-content: space-between;
}

.sidebar-container{
    padding: 0px 10px;
    font-family: 'Alegreya Sans', 'serif';
    font-size: 12px;
    font-weight: 500; 
    color: rgb(250, 244, 244); 
    text-shadow:
        -1px -1px 0 purple,
        1px -1px 0 purple,
        -1px 1px 0 purple,
        1px 1px 0 purple;
}

.sidebar-container h4{
    font-family: 'Alegreya Sans', 'serif';
    font-size: 13px;
    margin-bottom: 0px; 
    color: rgb(250, 244, 244); 
    font-weight: 500; 
    letter-spacing: 1.1px; 
    text-shadow:
        -1px -1px 0 purple,
        1px -1px 0 purple,
        -1px 1px 0 purple,
        1px 1px 0 purple;
}

@media(min-width: 900px){
    .sidebar-container h4{
        font-size: 14px; 
    }
}

.sidebar-container a{ 
    text-decoration: none; 
    color: rgb(113, 113, 252); 
}