.form-container{
    display: flex; 
    flex-wrap: wrap;
    justify-content: center; 
    font-family: 'Roboto', sans-serif;
    color: black; 
    margin: 7px 0px; 
    padding-bottom: 10px; 
}


@media(min-width: 595px){
    .form-container{
        flex-direction: column;
        align-items: center; 
    }
}


.form-container form{
    width: 95%; 
    text-align: center; 
    letter-spacing: 1px; 
    margin-bottom: 10px; 
}


@media(min-width: 595px){
    .form-container form{
        display: flex;
        flex-direction: column;
        align-items: center; 
    }
}
.form-container input{
    border: 2px solid rgb(250, 249, 249);
    height: 40px; 
    color: white;
    font-size: 20px;
    letter-spacing: 2px; 
    text-align: center;  
    background: rgba(255, 255, 255, 0.24); 
    border-radius: 4px; 

}

@media(min-width: 595px){
    .form-container input{
        width: 225px;
        height: 30px; 
    }
}

input:focus{
    outline: none; 
}

.button{
  background: white; 
  font-size: 18px; 
  padding: 11px; 
  letter-spacing: 2px; 
  color: purple;  
  margin: auto; 
  border: 2px solid rgb(66, 0, 100);
  border-radius: 4px; 
  font-weight: bold; 
  max-width: 280px;
  width: 90%;
}

@media(min-width: 595px){
    .button{
        font-size: 16px;
        padding: 5px;
        width: 219px; 
    }
}

.button:focus{
    outline: none; 
}

.form-container h1{
    font-size: 35px; 
    text-align: center; 
    font-weight: 100; 
    color: white; 
}

.form-container input::placeholder{
    text-align: center;
    font-size: 20px;  
    color: white; 
    opacity: 1; 
}

.form-container h3{
    text-shadow:none; 
    margin: 10px; 
    width: 258px; 
    color: white; 
    font-weight:100; 
    text-align: center;
    padding: 10px;
    box-shadow: 0px 1px 2px 2px black;
    border-radius: 25px; 
    background: rgb(66, 0, 100);
}

@media(min-width: 595px){
    .form-container h3{
        font-size: 16px;
        padding: 5px; 
        width: 219px; 
    }
}

@media(min-width: 595px){
    .sign-in-button{
        text-align: center; 
    }
}

.form-hr{
    width: 86%;
}

@media(min-width: 360px){
    .form-hr{
        max-width: 280px; 
    }
}

@media(min-width: 595px){
    .form-hr{
        width: 225px; 
    }
}

#contact-header{
    font-size: 45px; 
    margin-bottom: 35px; 
}

.contact-form input{
    margin-bottom: 25px; 
}


