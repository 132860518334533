.underground-card-container{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around;
    flex-direction: column-reverse;
}

@media(min-width: 360px){
    .underground-card-container{
        flex-direction: row-reverse;
    }
}

.underground-header{
    text-align: center; 
    font-family: 'Alegreya Sans', 'serif';
}

.underground-feed-container{
    background-color: white; 
    padding: 0px 10px; 
    border-bottom: 1px solid purple;
    border-top:1px solid purple;
    font-family: 'Alegreya Sans', 'serif';
    margin: 15px 0px; 
    font-weight: 700; 
}

@media(min-width: 360px){
    .underground-feed-container{
        width: 85%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 500px){
    .underground-feed-container{
        width: 90%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 700px){
    .underground-feed-container{
        width: 40%;
        box-shadow: 1px 2px 4px 2px;
    }
}

@media(min-width: 1000px){
    .underground-feed-container{
        width: 30%;
        box-shadow: 1px 2px 4px 2px;
    }
}

.underground-content-container h2{
    padding-top: 10px; 
    text-decoration:underline;
    font-size: 22px; 
}

.underground-feed-container p{
    font-size: 17px; 
    font-weight: 550; 
}

.underground-feed-image{
    min-width: 100%; 
    max-height: 160px; 
    border-top: 3px solid black; 
    border-bottom: 3px solid black; 
}

.underground-feed-image img{
    min-width: 100%; 
    max-height: 200px
}

.footer{
    display: flex; 
    flex-wrap: wrap; 
}

.footer a{
    text-align: left; 
}

.back-button{ 
    padding: 15px 0px; 
}


.mariannas-web-navbar{
    border: 2px solid black;
    border-radius: 50%; 
    padding: 3px;
}