nav{
    display: flex; 
    flex-wrap: wrap;  
    align-items: center; 
    justify-content: space-between; 
    padding: 0px 15px;
    background: black;   
}

.nav-header{
    margin: 15px; 
    font-family: 'Alegreya Sans', 'serif';
    font-size: 35px;
    color: rgb(250, 244, 244); 
    text-shadow:
        -1px -1px 0 purple,
        1px -1px 0 purple,
        -1px 1px 0 purple,
        1px 1px 0 purple;
}

.desktop-navbar-menu{
    display: none;
}

@media(min-width: 850px){
    .desktop-navbar-menu{
        display: flex; 
        flex-wrap: wrap; 
        font-size: 13px;
        width: 80%;
        justify-content: space-between;
        color: rgb(250, 244, 244); 
        text-shadow:
            -1px -1px 0 purple,
            1px -1px 0 purple,
            -1px 1px 0 purple,
            1px 1px 0 purple;
    }
}

.hide-myWeb{
    display: none;
}



