html {
  height: 100%; 
  scroll-behavior: smooth; 
}

body{
  margin: 0;
}

.content{
  height: 100%; 
  width: 100%; 
  background: url(purple-smoke.jpg);
  position: fixed; 
  background-repeat: no-repeat; 
  background-size: cover; 
  background-position: center;
  overflow: scroll; 
  scroll-behavior: smooth; 
}

@media(min-width:500px){
  .content{
    background-attachment: fixed; 
    position: none; 
    overflow:scroll; 
  }
}



