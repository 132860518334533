@font-face{
    font-family: zeit;
    src: url(Domine-Bold.ttf);
}

@font-face{
    font-family: zeitBold;
    src: url(Domine-Bold.ttf);
}

.card-container{
    cursor: pointer; 
    font-family: 'Russo One', sans-serif;
    color: black;
    background-repeat: no-repeat; 
    background-position:center; 
    background-size: cover; 
    width: 100%; 
    margin-top: 0px;
    border-bottom: 1px solid rgb(86, 0, 121);
}

@media(min-width:1000px){
    .card-container{
        width: 45%;
        margin-bottom: 15px; 
    }
}

@media(min-width:1100px){
    .card-container{
        width: 30%;
    }
}

.background-selector{
    width: 100%;
    background: #72018e1a;
    display: flex; 
    flex-wrap: wrap;
    align-items:center; 
    padding: 4px 0px;
}





.desktop-image-content{
    display: flex; 
    flex-wrap: wrap; 
    padding-left: 35px;  
    font-family: zeit;
}


.title{
    font-size: 14px; 
    font-weight: 800; 
    width: 100%;  
    display: flex; 
    flex-wrap: wrap; 
    justify-content: flex-start; 
    margin-top:0px;
    margin-bottom: 0px;
}

@media(min-width: 320px){
    .title p{
        display: flex;
        flex-wrap: wrap; 
        justify-content: center; 
        font-family: 'Russo One', sans-serif;
        align-items:center; 
        font-size: 14px;
        font-weight: 800; 
        color: rgb(0, 0, 0); 
        border-radius: 5px; 
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media(min-width: 450px){
    .title p{
        font-size: 17px;
    }
}

@media(min-width: 500px){
    .title p{
        font-size: 14px;
    }
}

@media(min-width:1000px){
    .title p{
        font-size: 22px;          
    }
}



.article-image{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; 
    justify-content: center;
    width: 39%;
    padding-left:1px; 
}

@media(min-width: 450px){
    .article-image{
        width: 34%;
    }
}

@media(min-width: 660px){
    .article-image{
        width: 30%;
        max-width: 130px; 
    }
}

@media(min-width: 1000px){
    .article-image{
        width: 95%;
        max-width: 95%;
    }
}





.newsCard-image{
    min-width: 100%;
    max-width: 100%;
    border-top: 6px solid rgb(10, 0, 0);
    border-bottom: 6px solid rgb(14, 0, 0); 
    border-radius: 15px;
    min-height: 105px;
    max-height: 105px; 

}

@media(min-width: 450px){
    .newsCard-image{
        min-height: 110px;
        max-height: 110px;
    }
}

@media (min-width: 500px){
    .newsCard-image {
        min-height: 90px;
        max-height: 90px;
    }
}

@media (min-width: 1000px){
    .newsCard-image {
        min-height: 160px;
        max-height: 160px;
    }
}


.hidden{
    display: none; 
}

.article-content{
        display: flex;
        flex-wrap:wrap;
        flex-direction:column-reverse;
        font-size: 14px;
        line-height: 1.4; 
        color: black; 
        font-size: 14px; 
        padding: 5px 5px;
        font-weight:700;
        margin-top: 5px; 
        background:rgba(197, 197, 197, 0.205);
}

@media(min-width: 700px){
    .article-content{
        width: 96%; 
        padding: 0px;  
        max-width: 480px; 
    }
}

.article-content p {
    margin-top: 10px; 
}

.article-content-teaser{
    display: none;   
}

.image-content-container{
    width: 100%;
    display: flex; 
    flex-wrap: wrap;
    align-items:center; 
    padding: 4px 0px;
    background: #4a005826
}

.author{
    color: rgb(0, 0, 180); 
    font-weight: 700;
    font-size: 12px; 
    font-family: 'Russo One', sans-serif;
}

@media(min-width: 450px){
    .author{
        font-size:14px; 
    }
}

@media(min-width: 500px){
    .author{
        font-size:13px; 
    }
}

p.author{
    margin-bottom: 0px; 
    margin-top: 0px; 
}

@media(min-width:1000px){
    p.author{
        font-size: 15px;    
        padding-top: 10px; 
        padding-bottom: 5px;       
    }
}

.timestamp{
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;  
}


.links{
    font-size: 20px;
    color: rgb(14, 13, 13);
    line-height: 0.7;
    font-family: 'Russo One', sans-serif;
    font-weight: 800;
    margin-bottom: 0px;
    margin-top:0px; 
}


@media(min-width: 450px){
    .links{
        font-size: 25px;
    }
}

@media(min-width: 500px){
    .links{
        font-size: 20px;
    }
}

.delete-button{
    text-align: center; 
}

.delete-button button{
    width: 85px;
    height: 40px; 
}

.spiderweb{
    height: 50px;
    width: 60px;  
}

hr{
    border-bottom-color: rgb(197, 197, 197);
    margin-bottom: 0px;
    margin-top: 0px; 
    width: 100%; 
}




.currents-banner{
    color: white;
    font-weight: normal;
    font-size: 35px; 
    margin-top:0px; 
    padding-top: 10px; 
    padding-bottom: 10px; 
    text-shadow: 1px 1px 2px rgb(82, 0, 99), 0 0 25px #f14247, 0 0 5px #c42fff;
    font-family: zeit; 
    width: 100%; 
    margin-bottom: 0px; 
    display: none; 
}

@media(min-width:350px){
    .currents-banner{
        font-size: 45px; 
    }
}

@media(min-width:561px){
    .currents-banner{
        display: none; 
    }
}

.currents-banner-top{
    display: none; 
}

@media(min-width: 361px){ 
    .currents-banner-top{
        display: block; 
        width: 100%; 
        font-weight: 700;
        text-align: center; 
        text-decoration: underline; 
        font-family: zeit;
        font-size: 35px;
        color: black;
        text-shadow:
            -1px -1px 0 white,
            1px -1px 0  purple,
            -1px 1px 0 white,
            1px 1px 0 purple;
    }
}

.genre{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 15px;
    font-size: 13px;
}

.author-timestamp-container{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between; 
    align-items: center;
    padding-top:10px;
    width: 100%;
}

@media(min-width:700px){
    .author-timestamp-container{
        max-width: 480px;
        
    }
}

.title-links-container{
    width: 56%;
    display: flex;
    flex-wrap: wrap; 
    flex-direction: column;
    align-items:flex-start;
    justify-content: space-between; 
    font-family: zeit; 
    padding-left: 8px;
}


@media(min-width: 500px){
    .title-links-container{
        width: 53%;
    }
}

@media(min-width: 660px){
    .title-links-container{
        width: 64%;
    }
}

@media(min-width: 750px){
    .title-links-container{
        width: 67%;
    }
}

@media(min-width: 850px){
    .title-links-container{
        width: 70%;
    }
}

.title-links-nopic{
    width: 100%; 
    padding: 0px 10px 5px 10px; 
}

@media(min-width:900px){
    .title-links-nopic{
        padding:0px 10px 5px 0px; 
    }
}

.title-links-nopic p{
    margin-bottom: 15px; 
    font-size: 16px; 
}

.author-genre-container{
    display:flex; 
    flex-wrap:wrap; 
    justify-content: space-between; 
    width: 100%; 
    align-items: baseline; 
}

.desktop-container-main{
    display: none; 
}

@media(min-width: 1000px){
    .mobile-container{
        display: none; 
    }
}

@media(min-width: 1000px){
    .desktop-container-main{
        display: flex; 
        flex-wrap: wrap;
        flex-direction: column
    }
}

.desktop-content{
    display: none; 
}


@media(min-width: 1000px){
    .desktop-content{
        display: flex; 
        font-size: 14px; 
        color: black; 
    }

}

