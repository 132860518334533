.contact-card-container{
    border: 1px solid white; 
    width: 100%;
}

.message-info{
    width: 100%;
    display: flex; 
    flex-wrap: wrap; 
    color: white; 
    justify-content: space-around;
}

.message-closed{
    display: none; 
}

.message-open{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column; 
    color: white; 
    padding-bottom: 20px; 
}

.contact-delete-button{
    border: 1px solid white; 
    text-align: center; 
    width: 200px; 
    margin: 0 auto; 
}