.myWeb-form-container{
    width: 100%; 
    text-align: center;
}

input{
    max-width: 280px;
    width: 90%; 
    height: 40px; 
    border: 2px solid rgb(250, 249, 249);
    background: rgba(255, 255, 255, 0.24); 
    margin-bottom: 25px; 
    color: white;
    font-size: 19px;
    letter-spacing: 2px; 
    text-align: center;  
    border-radius: 4px; 
}

input::placeholder{
    color: white; 
}

textarea{
    max-width: 295px;
    width: 90%; 
    height: 120px; 
    margin-bottom: 25px; 
    background: rgba(255, 255, 255, 0.24); 
    border: 2px solid rgb(250, 249, 249);
    color: white; 
    font-size: 17px; 
    border-radius: 4px; 
}

textarea::placeholder{
    text-align: center;  
    color: white;
    padding-top: 50px; 
    font-size: 20px; 
    font-family: Arial;
    letter-spacing: 1.5px; 
}


.myWeb-form-container form{
    width: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
}

@media(min-width: 760px){
    .myWeb-form-container form{
        flex-direction: column;
        align-items:center;
    }
}

.myWeb-form-container p{
    margin-top: 0px;
    padding: 11px; 
    font-family: Arial; 
    background: white; 
    font-size: 18px; 
    letter-spacing: 2px; 
    color: purple;  
    margin: auto; 
    border: 2px solid rgb(66, 0, 100);
    border-radius: 4px; 
    font-weight: bold; 
    max-width: 280px;
    width: 90%;
}

.post-news-header{
    border-bottom: 1px solid rgb(253, 252, 252);
    display: inline-block;
    font-family: Arial; 
    color: white; 
    margin-bottom: 30px; 
}

