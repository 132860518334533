.chat-container{
    background: rgba(87, 0, 87, 0.308);
    width: 100%;  

}

.chat-container form{
    width: 100%; 
    display: flex; 
    flex-wrap: wrap;  
}

.chat-container form input{
    width: 76%; 
    height: 40px; 
}

.chat-container form button{
    width: 22.5%; 
    height: 46px; 
}

.chat-board p{
    font-size: 18px; 
    padding: 5px; 
}



.chat-board span{
    font-weight: bold; 
    letter-spacing: 1.1px;
    padding-left: 5px; 
}



.chat-board{
    background: purple; 
    width: 100%; 
    overflow: auto;
    height: 410px; 
 
}




 







